:root {
  --w-primary-dark3: #0f0f0f;
  --w-primary-dark2: #0a0a0a;
  --w-primary-dark1: #212121;
  /* --w-primary-main: rgb(55, 114, 255); */
  --w-primary-main: #f2295b;
  --w-primary-main2: #e41348;
  --w-primary-main3: #fa5f86;
  --w-primary-main-100: #fec9d7;

  /* --w-primary-main: #1f1f1f; */
  --w-primary-light4: rgb(52, 53, 65);
  --w-primary-light3: rgb(32, 33, 35);
  --w-primary-light2: #0a0a0a;
  --w-primary-light1: #0f0f0f;
  --w-text-main: #212121;
  --w-text-sub6: #797979;
  --w-text-sub5: #bcbcbc;
  --w-text-sub4: #e8e8e8;
  --w-text-sub3: #f3f3f3;
  --w-text-sub2: #fbfbfb;
  --w-text-sub1: #ffffff;

  --w-body-bg: rgb(255, 255, 255);
  --w-secondary-color: rgba(33, 37, 41, 0.75);
  --w-secondary-bg: rgb(233, 236, 239);
  --w-territory-color: rgba(33, 37, 41, 0.5);
  --w-emphasis-color: rgb(0, 0, 0);
  --w-dark-color: rgb(33, 37, 41); /* 배경색 */
  --w-dark-bg-subtle-color: rgb(206, 212, 218);

  --w-info-color: #2fbcf2; /* 정보를 나타내는 밝은 파란색 */
  --w-warning-color: #f2e229; /* 경고를 나타내는 밝은 노란색 */
  --w-error-color: #f22f2f; /* 오류를 나타내는 진한 빨간색 */
  --w-blue: #0d6efd;
  --w-indigo: #6610f2;
  --w-purple: #6f42c1;
  --w-pink: #d63384;
  --w-red: #dc3545;
  --w-orange: #fd7e14;
  --w-yellow: #fbff07;
  --w-yellow-100: #fff9c2;
  --w-yellow-200: #fff382;
  --w-green: #198754;
  --w-green-100: #d4edda;
  --w-green-200: #c3e6cb;
  --w-green-300: #b1dfbb;
  --w-green-400: #a3dab9;
  --w-green-500: #7bcf9e;
  --w-green-600: #6cc48a;
  --w-green-700: #4bb370;
  --w-teal: #20c997;
  --w-cyan: #0dcaf0;
  --w-black: #000;
  --w-white: #fff;
  --w-gray: #6c757d;
  --w-gray-dark: #343a40;
  --w-gray-50: #f8f9fa;
  --w-gray-100: #f8f9fa;
  --w-gray-200: #e9ecef;
  --w-gray-300: #dee2e6;
  --w-gray-400: #ced4da;
  --w-gray-500: #adb5bd;
  --w-gray-600: #6c757d;
  --w-gray-700: #495057;
  --w-gray-800: #343a40;
  --w-gray-900: #212529;
  --w-primary-text-emphasis: #052c65;
  --w-secondary-text-emphasis: #2b2f32;
  --w-success-text-emphasis: #0a3622;
  --w-info-text-emphasis: #055160;
  --w-warning-text-emphasis: #664d03;
  --w-danger-text-emphasis: #58151c;
  --w-light-text-emphasis: #495057;
  --w-dark-text-emphasis: #495057;

  --w-border-width: 1px;

  --bs-primary-rgb: 242, 41, 91;
  --bs-link-color-rgb: 242, 41, 91;
  --bs-primary-border-subtle: #fc6c90;
}

a {
  cursor: pointer;
}

.swal2-icon {
  border-color: transparent !important;
}
.swal-custom-icon {
  height: 80px;
}

.swal2-confirm {
  background-color: var(--w-primary-main) !important;
}

.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-cancel {
  background-color: transparent !important;
  border: 1px solid var(--w-gray-600) !important;
  color: var(--w-primary-dark1) !important;
}

.swal2-title {
  font-size: 1.6rem !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--w-primary-main);
  --bs-btn-border-color: var(--w-primary-main);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--w-primary-main2);
  --bs-btn-hover-border-color: var(--w-primary-main2);
  --bs-btn-focus-shadow-rgb: 156, 90, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--w-primary-main2);
  --bs-btn-active-border-color: var(--w-primary-main2);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(2, 6, 23, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--w-primary-main);
  --bs-btn-disabled-border-color: var(--w-primary-main);
}

.bg-primary-gradient {
  background: linear-gradient(
    180deg,
    var(--w-primary-main3),
    var(--w-primary-main) 47.92%,
    var(--w-primary-main2)
  );
}

.collapse a:hover {
  color: var(--w-primary-main) !important;
}

.nav-account .nav-item .nav-link.active {
  background-color: var(--w-primary-main);
  color: #fff !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(254, 201, 215, 0.2) !important;
}

.btn-social {
  background-color: var(--bs-gray-100);
  border: 1px solid var(--bs-gray-200);
  color: var(--bs-gray-500);
}

.btn-social:hover {
  background-color: var(--w-primary-main);
  color: #fff !important;
}

.manual-img {
  width: 100%;
  height: auto;
  max-width: 600px;
  margin-bottom: 10px;
}

.form-control:focus {
  background-color: var(--bs-body-bg);
  border-color: var(--w-primary-main);
  box-shadow: 0 0 0 0.25rem rgba(242, 41, 91, 0.25);
  /* color: var(--bs-body-color); */
  outline: 0;
}

.icon-xlg {
  height: 8rem;
  line-height: 8rem;
  width: 8rem;
}

.top-43 {
  top: 43%;
}

.text-capitalize {
  text-transform: capitalize;
}
